<template>
  <div style="width: 100%">
    <div class="container">
      <div class="header">
        <p>停车位预约信息</p>
      </div>
      <div class="parkDate">
        <p class="BarTitle">日期：</p>
        <p class="date">{{ year }} 年 {{ month }} 月 {{ day }} 日</p>
        <div class="parkTime">
          <span
            class="selectableItem"
            :class="{ selectedItem: index == checkingIndex }"
            v-for="(item, index) in parkTimes"
            :key="item.time"
            :data-id="index"
            @click="changeTime(index)"
            >{{ item.time }}</span
          >
        </div>
      </div>
      <div class="ReserveDetail">
        <p class="BarTitle">该时间段停车预约信息：</p>
        <div
          v-for="item in this.ReserveDetail[this.checkingIndex]"
          :key="item.ID"
        >
          <reserve-detail v-if="item.status == 0" :item="item" @refresh='refresh' />
        </div>
      </div>
      <div class="FinishReserve ReserveDetail">
        <p class="BarTitle">已进场车辆：</p>
        <div
          v-for="item in this.ReserveDetail[this.checkingIndex]"
          :key="item.ID"
        >
          <finish-reverse v-if="item.status == 1" :item="item" @refresh='refresh' />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FinishReverse from "../components/FinishReverse.vue";
import ReserveDetail from "../components/ReserveDetail.vue";
export default {
  components: { ReserveDetail, FinishReverse },
  data() {
    return {
      day: "",
      month: "",
      year: "",
      parkTimes: [
        {
          time: "8:00 - 12:00",
          selectable: true,
          selected: false,
        },
        {
          time: "12:00 - 16:00",
          selectable: true,
          selected: false,
        },
        {
          time: "16:00 - 20:00",
          selectable: true,
          selected: false,
        },
        {
          time: "20:00 - 23:00",
          selectable: true,
          selected: false,
        },
        /* {
          time: "15:00 - 16:00",
          selectable: true,
          selected: false,
        },
        {
          time: "16:00 - 17:00",
          selectable: true,
          selected: false,
        }, */
      ],
      ReserveDetail: [],
      FinishReserve: [[], [], [], []]/* [[], [], [], [], [], []] */,
      checkingIndex: 0,
      refreshMutex: false,
    };
  },
  methods: {
    getCurrentTime() {
      this.year = new Date().getFullYear();
      this.month = new Date().getMonth() + 1;
      this.day = new Date().getDate();
    },
    changeTime(index) {
      this.checkingIndex = index;
      console.log(this.checkingIndex);
    },
    getDailyParking() {
      this.$axios.get("/Parking/getDailyReserveDetail").then((res) => {
        let data = res.data.data;
        this.ReserveDetail = data;
        this.ReserveDetail = res.data.data;
        console.log(this.ReserveDetail);
        this.changeTime(0);
      });
    },
    refresh(data) {
      this.getDailyParking()
    },
  },
  mounted() {
    this.getCurrentTime();
  },
  created() {
    this.getDailyParking();
  },
};
</script>
<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.container {
  position: relative;
  transition: all 0.5s;
}

.header {
  position: relative;
  background-color: #1f89eb;
  height: 50px;
  width: 100%;
  margin-bottom: 20px;
  z-index: 9999;
}

.header > p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: PingFang SC;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
}

.BarTitle {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: #0e0e0e;
}

.date {
  position: absolute;
  top: 2px;
  left: 65px;
  display: inline-block;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #0e0e0e;
}

.parkDate {
  width: 80%;
  height: 200px;
  margin: auto;
  margin-bottom: 40px;
  position: relative;
}

.parkTime {
  position: absolute;
  top: 50px;
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  /* align-content: space-between; */
}

.selectableItem {
  width: 45%;
  height: 40px;
  left: 37px;
  top: 358px;
  background: #f4f4f5;
  border-radius: 20px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  line-height: 40px;
  color: #263238;
  transition: all 0.5s;
}

.selectedItem {
  background: #1f89eb;
  color: #ffffff;
}

.ReserveDetail {
  width: 80%;
  margin: auto;
  /* margin-bottom: 40px; */
  padding-top: 40px;
  position: relative;
}
</style>
