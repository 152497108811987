<template>
  <div id="app">
    <home />
  </div>
</template>

<script>
import Home from './views/Home.vue';
/* import Home from './views/Home.vue'; */

export default {
  name: "App",
  components: {
    Home
    /* Home, */
  },
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {
  },
  destroyed() {
  },
  created() {
  },
};
</script>

<style>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
