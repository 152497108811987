<template>
  <div style="width: 100%">
    <div class="container" @click="showDetail">
      <p class="name">{{ detail.owner_name }}</p>
      <p class="gender">{{ detail.owner_gender == 1 ? "先生" : "女士" }}</p>
      <p class="phone">{{ detail.phone_number }}</p>
      <p class="carNumber">
        {{ detail.car_numer_perfix }} {{ detail.car_numer_suffix }}
      </p>
      <img class="car" src="../static/img/greenCar.png" alt="" />
    </div>
    <div class="detailBox" v-if="showDetailMutex">
      <p class="name" style="margin-bottom: 15px">{{ detail.owner_name }}</p>
      <p class="gender">{{ detail.owner_gender == 1 ? "先生" : "女士" }}</p>
      <p class="phone">{{ detail.phone_number }}</p>
      <p class="carNumber" style="font-size: 20px">
        {{ detail.car_numer_perfix }} {{ detail.car_numer_suffix }}
      </p>
      <img
        class="car"
        style="top: 27px; height: 61.46px"
        src="../static/img/greenCar.png"
        alt=""
      />
      <div class="finishBtn disableItem" style="bottom: 69px" @click="showDetail">关闭</div>
      <div class="finishBtn" @click="passTheReserve">进场</div>
    </div>
    <div class="background" v-if="showDetailMutex"></div>
  </div>
</template>

<script>
import qs from 'qs';

export default {
  props: ["item"],
  data() {
    return {
      detail: this.item,
      showDetailMutex: false,
      finalData: {"ID": this.item.ID},
    };
  },
  methods: {
    con() {
      console.log(this.detail);
    },
    showDetail() {
      this.showDetailMutex = !this.showDetailMutex;
    },
    passTheReserve() {
      console.log(qs.stringify(this.finalData))
      this.$axios({
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/Parking/updateReserveByID',
        data: qs.stringify(this.finalData),
      }).then(
        (res) => {
          console.log(res)
          this.showDetail();
          this.$emit('refresh', true)
        }
      )
    },
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.container {
  position: relative;
  transition: all 0.5s;
  font-family: PingFang SC;
  font-style: normal;
  height: 80px;
  width: 100%;
  background: #f4f4f5;
  border-radius: 10px;
  margin-bottom: 20px;
  padding-left: 27px;
  padding-top: 11px;
  color: #0e0e0e;
}

.name {
  font-size: 18px;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 9px;
}

.gender {
  font-size: 14px;
  display: inline-block;
  margin-right: 10px;
}

.phone {
  font-size: 14px;
  display: inline-block;
  position: relative;
  bottom: -1px;
}

.carNumber {
  font-weight: bold;
  font-size: 18px;
}

.car {
  position: absolute;
  height: 54.46px;
  right: -20px;
  bottom: -13px;
}

.background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  background: #292f32;
  opacity: 0.5;
}

.detailBox {
  width: 80%;
  height: 216px;
  background: #ffffff;
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9998;
  padding-left: 27px;
  padding-top: 15px;
}

.finishBtn {
  position: absolute;
  bottom: 13px;
  left: 10%;
  width: 80%;
  height: 40px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 40px;
  text-align: center;
  color: #ffffff;
  background: #1f89eb;
  border-radius: 50px;
}

.disableItem {
  background: #ffffff;
  border: 1px solid #f4f4f5;
  color: #dbdbdb;
}
</style>